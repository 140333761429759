<template>
  <div class="help-page">
    <div class="help-page__text-container">
      <div class="help-page__title">Looking for a cocktail idea&nbsp;?</div>
      <div class="help-page__step">
        <div class="help-page__step-number">1</div>
        <div class="help-page__step-text">
          Tell me what ingredients you have <br />at your disposal
        </div>
      </div>
      <div class="help-page__step">
        <div class="help-page__step-number">2</div>
        <div class="help-page__step-text">
          Click on "Find a cocktail" and I'll tell <br />you what you can make
          with that!
        </div>
      </div>
      <router-link to="/" class="help-page__button shake-slow">
        Let's Shake!
      </router-link>
    </div>
    <div class="help-page__vector">
      <img alt="welcome icon" src="../assets/svg/welcome.svg" v-svg-inline />
    </div>
  </div>
</template>

<script>
export default {
  name: "help-page",
};
</script>
